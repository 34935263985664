<script lang="ts" setup>
import { ref, toRef, watch } from '#imports';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const icon = ref<any>('');

try {

  const iconsImport = import.meta.glob(`assets/icons/generic/*.svg`, {
    as: 'raw',
    eager: false,
  });
  icon.value = await iconsImport[`/assets/icons/generic/${props.name}.svg`]();

  watch(toRef(props, 'name'), async () => {
    icon.value = await iconsImport[`/assets/icons/generic/${props.name}.svg`]();
  });

} catch (e) {
    console.error(`The ${props.name} icon does not exist`);
}
</script>

<template>
  <span class="block" v-html="icon"></span>
</template>

<style lang="scss" scoped></style>